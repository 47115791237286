import { m } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { Button, Box, Container, Stack, Typography } from '@mui/material';
import { bgGradient } from '../../utils/cssStyles';
import { MotionViewport, varFade } from '../../components/animate';
import React from 'react';
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
import useLocales from '@/locales/useLocales';
import { isMobile } from 'react-device-detect';
import BigHomeButton from '@/components/buttons/homeMobile';

// ----------------------------------------------------------------------

export default function HomeAdvertisement() {
  const theme = useTheme();

  return (
    <Container component={MotionViewport}>
      <Stack
        alignItems="center"
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          // ...bgGradient({
          //   direction: '135deg',
          //   startColor: theme.palette.primary.dark,
          //   endColor: theme.palette.background.default,
          //   imgUrl: undefined,
          //   color: undefined,
          // }),
          // boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.2)',
          // borderRadius: 2,
          pb: { xs: 5, md: 0 },
        }}
      >
        <Content />
        <Description />
      </Stack>
    </Container>
  );
}

// ----------------------------------------------------------------------

function Description() {
  const { translate } = useLocales();
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: theme.spacing(12),
        width: '100%',
        textAlign: {
          xs: 'center',
        },
        // padding: { xs: 5, md: 15 },
      }}
    >
      <Box
        component={m.div}
        variants={varFade().inDown}
        sx={{ color: 'common.white', mb: 5, typography: 'h2', textShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.2)', width: '100%' }}
      >
        <Typography variant="h2" component="div">
          {translate('home.homeAdvertisementTitle')
            .split('<br />')
            .map((text: string, index: number) => (
              <React.Fragment key={index}>
                {text}
                {index < translate('home.homeAdvertisementTitle').split('<br />').length - 1 && <br />}
              </React.Fragment>
            ))}
        </Typography>
      </Box>

      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={{ xs: 'center' }} spacing={2} width="100%">
        <m.div variants={varFade().inRight}>
          <BigHomeButton text={translate('menu.signIn')} url={PATH_AUTH.login} />
        </m.div>

        <m.div variants={varFade().inRight}>
          <BigHomeButton text={translate('home.homeAdvertisementHowItWorksButton')} url={PATH_PAGE.howItWorks} />
        </m.div>
      </Stack>
    </Box>
  );
}

// ----------------------------------------------------------------------

function Content() {
  return (
    <>
      <Stack component={m.div} variants={varFade().inUp} alignItems="center">
        <m.div
          animate={{
            y: [-20, 0, -20],
          }}
          transition={{ duration: 4, repeat: Infinity }}
        ></m.div>
      </Stack>
    </>
  );
}
