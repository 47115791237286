import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useRouter } from 'next/router';
import { isMobile } from 'react-device-detect';

interface BigButtonProps extends ButtonProps {
  text: string | (string | React.ReactElement)[];
  url?: string;
  onClick?: () => void;
  fullWidth?: boolean;
}

const BigHomeButton: React.FC<BigButtonProps> = ({ text, url, onClick, fullWidth = true, ...rest }) => {
  const router = useRouter();

  const handleClick = () => {
    if (url) {
      router.push(url, undefined, { shallow: true });
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      variant="contained"
      fullWidth={fullWidth}
      size="large"
      color={rest?.color || 'primary'}
      sx={{
        py: 2,
        fontSize: isMobile ? '1.05rem' : '1.2rem',
        fontWeight: 'bold',
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        pointerEvents: 'all',
        ...rest.sx,
      }}
      onClick={handleClick}
      {...rest}
    >
      {text}
    </Button>
  );
};

export default BigHomeButton;
