import { useScroll } from 'framer-motion';
import { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Stack, Typography } from '@mui/material';
import useResponsive, { useAspectRatio } from '../../hooks/useResponsive';
import styles from './HomeHero.module.css';
import { isIOS, isMobile } from 'react-device-detect';
import { cssVariables } from './HomeHeroCssVariables';
import MagicWand from './MagicWand';
import { useAtom } from 'jotai';
import useLocales from '@/locales/useLocales';
import BigHomeButton from '@/components/buttons/homeMobile';
import Headline, { SubHeadline } from '@/components/text/headlines';
import { PATH_AUTH } from '@/routes/paths';
import { gsap } from 'gsap';
import { safeAreaInsetTopAtom } from '@/src/atoms/_app.states';
import Iconify from '@/components/iconify';

const StyledRoot = styled('div')(({ theme }) => ({
  // position: 'fixed',
  minHeight: '100vh', // Set minimum height to 100vh
  display: 'flex', // Use flexbox for vertical alignment
  flexDirection: 'column', // Stack content vertically
  justifyContent: 'center', // Center content vertically
  transition: 'all 0.5s ease-in-out', // Add smooth transition for all properties
  pointerEvents: 'none',
  [theme.breakpoints.up('xl')]: {
    top: 0,
    left: 0,
    width: '100%', // Change width to 100%
    height: '100vh',
    position: 'fixed',
  },
}));

export default function HomeHero({ shareMode = false }) {
  const isDesktop = useResponsive('up', 'md');
  const { isWidthGreater, percentDifference } = useAspectRatio();
  const { scrollYProgress } = useScroll();
  const [hide, setHide] = useState(false);
  const { translate } = useLocales();
  const [safeAreaInsetTop] = useAtom(safeAreaInsetTopAtom);
  const boxRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);

  // Ensure window is defined before accessing it
  const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const innerHeight = typeof window !== 'undefined' ? window.innerHeight : 0;

  useEffect(() => {
    gsap.fromTo(boxRef.current, { opacity: 0, y: -50 }, { opacity: 1, y: 0, duration: 1, ease: 'power3.out' });
  }, []);

  useEffect(() => {
    const handleScroll = (scrollHeight: number) => {
      setHide(scrollHeight > 0.8);
    };
    const unsubscribe = scrollYProgress.on('change', handleScroll);
    return () => unsubscribe();
  }, [scrollYProgress]);

  useEffect(() => {
    const handleResize = () => {
      setScale(+(window.innerHeight / boxRef.current?.clientHeight!).toFixed(2) - 0.25);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const HowItWorks = () => (
    <Box className="text-container">
      {'∙ ∙ ∙'.split('').map((char, index) => (
        <span className={`glowing-text ${char === ' ' ? 'space' : ''}`} style={{ animationDelay: `${index * 0.5}s` }} key={index}>
          {char === ' ' ? '\u00A0' : char}
        </span>
      ))}
    </Box>
  );

  return (
    <>
      <StyledRoot
        sx={{ ...(hide && { opacity: 1 }), transition: 'opacity 0.3s ease-in-out' }}
        // @ts-ignore
        style={cssVariables}
      >
        <Box className={styles['gradient-bg']} sx={{ height: '100vh' }}>
          <div className={styles['gradients-container']}>
            {isIOS ? (
              <>
                <div className={styles['g1']} style={{ top: '50%', width: '100%' }}></div>
                <div className={styles['g2']} style={{ top: '50%', width: '100%' }}></div>
                <div className={styles['g3']} style={{ top: '50%', width: '100%' }}></div>
                <div className={styles['g4']} style={{ top: '50%', width: '100%' }}></div>
                <div className={styles['g5']} style={{ top: '50%', width: '100%' }}></div>
                <div className={styles['g6']} style={{ top: '50%', width: '100%' }}></div>
              </>
            ) : (
              <>
                <div className={styles['g1']} style={{ top: '0%' }}></div>
                <div className={styles['g2']} style={{ top: '0%' }}></div>
                <div className={styles['g3']} style={{ top: '0%' }}></div>
                <div className={styles['g4']} style={{ top: '0%' }}></div>
                <div className={styles['g5']} style={{ top: '0%' }}></div>
                <div className={styles['g6']} style={{ top: '0%' }}></div>
              </>
            )}
          </div>
        </Box>
        <Box
          sx={{
            position: 'fixed',
            width: '100%',
            pt: `${isIOS && safeAreaInsetTop}px`,
            flexGrow: 1, // Allow the content to grow and fill available space
            display: 'flex', // Use flexbox for vertical alignment
            flexDirection: 'column', // Stack content vertically
            justifyContent: 'center', // Center content vertically
            transform: isWidthGreater && percentDifference > 50 ? `scale(${scale})` : 'scale(1)',
          }}
          ref={boxRef}
        >
          <Container
            maxWidth="lg"
            sx={{
              transform: isWidthGreater && percentDifference > 50 ? `scale(${scale})` : 'scale(1)',
              transformOrigin: 'center center', // Ensure scaling is centered
            }}
          >
            <Stack spacing={isDesktop ? 9 : innerHeight < 800 ? 3 : 6}>
              <Headline text={translate('home.homeHeroTitlePhotographer')} sx={{ textAlign: 'center' }} />
              {/* <Headline text={`${translate('inAppEvent.title')} 🎉`} sx={{ textAlign: 'center' }} /> */}
              <MagicWand />
              <SubHeadline text={translate('home.homeHeroDescription')} sx={{ textAlign: 'center', paddingTop: 1 }} />
              {/* {innerHeight > 800 && (
                <Typography variant="h2" sx={{ letterSpacing: 0.2, opacity: 0.7, textAlign: 'center' }}>
                  <HowItWorks />
                </Typography>
              )} */}
              <Stack spacing={3} sx={{ textAlign: 'center' }}>
                {/* {innerHeight > 800 && <BigHomeButton text={translate('AIPortraitShowcase.seeTheMagic')} url="#AIShowcase" />} */}
                <BigHomeButton text={translate('menu.signIn')} url={PATH_AUTH.login} />
              </Stack>
              {/* {innerHeight >= 600 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    zIndex: 3,
                    position: 'absolute',
                    bottom: -100,
                    left: '50%',
                    right: 0,
                    transform: 'translateX(-50%)',
                    flexDirection: 'column',
                    cursor: 'pointer',
                  }}
                >
                  <Iconify
                    icon={'mdi:arrow-down'}
                    sx={{
                      width: 48,
                      height: 48,
                      opacity: 0.7,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: 1,
                      animation: 'scrollText 2s infinite',
                      color: 'white',
                    }}
                  >
                    {translate('welcomeTour.scrollDownIntro')}
                  </Typography>
                </Box>
              )} */}
            </Stack>
          </Container>
        </Box>
      </StyledRoot>
      <Box sx={{ minHeight: { md: '100vh', zIndex: 1000 } }} />
    </>
  );
}
