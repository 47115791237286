import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Box, Grid, Container, Typography, Stack } from '@mui/material';
import { filterStyles, bgGradient } from '../../utils/cssStyles';
import { MotionViewport, varFade } from '../../components/animate';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useLocales from '@/locales/useLocales';
import useResponsive from '@/hooks/useResponsive';
import BigHomeButton from '@/components/buttons/homeMobile';
import { PATH_AUTH } from '@/routes/paths';

const StyledRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  '&:before': {
    height: 2,
    bottom: -1,
    zIndex: 11,
    content: '""',
    width: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.grey[900],
  },
}));

const StyledWrap = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: alpha(theme.palette.grey[500], 0.08),
}));

const StyledDescription = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  textAlign: 'center',
  position: 'absolute',
  paddingTop: theme.spacing(10),

  ...bgGradient({
    startColor: `${theme.palette.background.default} 25%`,
    endColor: alpha(theme.palette.background.default, 0),
    direction: '',
    imgUrl: undefined,
    color: undefined,
  }),
  [theme.breakpoints.up('md')]: {
    background: 'unset',
    position: 'unset',
    textAlign: 'left',
    padding: theme.spacing(25, 0),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.down('md')]: {
    background: 'unset',
    position: 'unset',
    textAlign: 'left',
  },
}));

const StyledContent = styled(m.img)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    top: 1,
    zIndex: 8,
    minHeight: 'auto',
    position: 'absolute',
    boxShadow: `-40px 80px 80px ${theme.palette.mode === 'light' ? alpha(theme.palette.grey[500], 0.4) : theme.palette.common.black}`,
  },
}));

// ----------------------------------------------------------------------

export default function HomeForDesigner() {
  const { translate } = useLocales();

  const headerHeight = typeof document !== 'undefined' ? document.getElementById('header-main')?.clientHeight : 0;
  const innerHeight = typeof window !== 'undefined' ? window.innerHeight : 0;

  return (
    <StyledRoot>
      <StyledWrap>
        <div id="createHome" style={{ position: 'relative', top: `30px` }} />

        <Container component={MotionViewport} sx={{ position: 'relative', pb: isMobile ? 3 : 0 }}>
          <Grid container>
            <Grid item md={6}>
              <Description />
            </Grid>

            <Grid item md={6}>
              <StyledContent src="/assets/images/home/screenshot-home-mira.jpg" variants={varFade().in} />
            </Grid>
          </Grid>
          {innerHeight > 600 && isMobile && (
            <Stack spacing={3} sx={{ marginTop: 4, marginBottom: 4, textAlign: 'center' }}>
              {/* <BigHomeButton text={translate('buttons.moreInfo')} url="#homeEnd" /> */}
              {/* <BigHomeButton text={translate('menu.signIn')} url={PATH_AUTH.login} /> */}
            </Stack>
          )}
        </Container>

        <TriangleShape />

        <TriangleShape anchor="bottom" />
      </StyledWrap>
    </StyledRoot>
  );
}

// ----------------------------------------------------------------------

function Description() {
  const { translate } = useLocales();
  return (
    <StyledDescription>
      <m.div variants={varFade().inUp}>
        <Typography component="div" variant="overline" sx={{ color: 'text.disabled' }}>
          {translate('home.homeForDesignerTitle')}
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography variant="h2" sx={{ my: 3 }}>
          {translate('home.homeForDesignerSubtitle')}
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          sx={{
            mb: 5,
            color: 'text.secondary',
          }}
        >
          {translate('home.homeForDesignerDescription')}
        </Typography>
      </m.div>
    </StyledDescription>
  );
}

TriangleShape.propTypes = {
  anchor: PropTypes.oneOf(['top', 'bottom']),
};

function TriangleShape({ anchor = 'top', ...other }) {
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        width: 1,
        position: 'absolute',
        color: 'background.default',
        zIndex: { xs: 0, md: 9 },
        height: { xs: 40, md: 64 },
        ...filterStyles(`drop-shadow(320px 20px 80px ${isLight ? alpha(theme.palette.grey[700], 0.4) : theme.palette.common.black})`),
        ...(anchor === 'bottom' && {
          zIndex: 9,
          bottom: 0,
          top: 'unset',
          color: 'grey.900',
          transform: 'scale(-1)',
          ...filterStyles('none'),
        }),
      }}
      {...other}
    >
      <svg width="100%" height="100%" viewBox="0 0 1440 64" preserveAspectRatio="none">
        <path d="M1440 0H0L1440 64V0Z" fill="currentColor" />
      </svg>
    </Box>
  );
}
