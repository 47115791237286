import { useEffect, useRef, useState } from 'react';
import styles from './MagicWand.module.css';
import React from 'react';
import useResponsive from '@/hooks/useResponsive';
import { gsap } from 'gsap';
import AppConfig from '@/config/config';

const allImages = Array.from({ length: 150 }, (_, i) =>
  AppConfig.version === 'dev'
    ? `/assets/images/homeExamples/${i + 1}.jpg`
    : `/cdn-cgi/image/width=600,quality=75/assets/images/homeExamples/${i + 1}.jpg`
);

const MagicWand = () => {
  const tilesRef = useRef<HTMLDivElement>(null);
  const isUnder800px = useResponsive('down', 800);
  const tileCount = isUnder800px ? 3 : 4;
  const [initialImages] = useState(() => Array.from({ length: tileCount }, () => allImages[Math.floor(Math.random() * allImages.length)]));

  useEffect(() => {
    const updateImage = (index: number) => {
      const imgContainer = tilesRef.current?.children[index] as HTMLElement;
      if (imgContainer) {
        const oldImg = imgContainer.querySelector('img') as HTMLImageElement;
        let newImgSrc;

        // Ensure the new image is not the same as the current one
        do {
          newImgSrc = allImages[Math.floor(Math.random() * allImages.length)];
        } while (newImgSrc === oldImg.src);

        const newImg = document.createElement('img');
        newImg.src = newImgSrc;
        newImg.style.position = 'absolute';
        newImg.style.top = '0';
        newImg.style.left = '0';
        newImg.style.width = '100%';
        newImg.style.height = '100%';
        newImg.style.objectFit = 'cover';
        newImg.style.opacity = '0';

        imgContainer.appendChild(newImg);

        // Create a background div for the "hole" effect
        const bgDiv = document.createElement('div');
        bgDiv.style.position = 'absolute';
        bgDiv.style.top = '0';
        bgDiv.style.left = '0';
        bgDiv.style.width = '100%';
        bgDiv.style.height = '100%';
        bgDiv.style.background = 'radial-gradient(circle at center, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 70%)';
        bgDiv.style.opacity = '0';
        imgContainer.appendChild(bgDiv);

        // Animate the transition
        const tl = gsap.timeline();

        tl.to(oldImg, { opacity: 0, duration: 0.3 }, 0)
          .to(bgDiv, { opacity: 1, duration: 0.3 }, 0)
          .to(bgDiv, {
            background: 'radial-gradient(circle at center, rgba(255,255,255,0.8) 100%, rgba(0,0,0,0) 100%)',
            duration: 0.3,
            ease: 'power2.in',
          })
          .to(newImg, { opacity: 1, duration: 0.3 })
          .to(bgDiv, { opacity: 0, duration: 0.3 }, '-=0.3')
          .call(() => {
            oldImg.remove();
            bgDiv.remove();
          });
      }
    };

    const startUpdating = (index: number) => {
      const randomTime = Math.random() * (7000 - 3000) + 3000;
      setTimeout(() => {
        updateImage(index);
        startUpdating(index);
      }, randomTime);
    };

    // Start the update cycle immediately
    Array.from({ length: tileCount }).forEach((_, index) => startUpdating(index));

    return () => {
      // No cleanup needed
    };
  }, [tileCount]);

  return (
    <div id="tiles" className={styles['tiles']} ref={tilesRef}>
      {initialImages.map((src, index) => (
        <div key={index} className={`${styles['tile']} tile`} style={{ transition: 'all 0.3s ease-in-out', overflow: 'hidden' }}>
          <img src={src} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
      ))}
    </div>
  );
};

export default MagicWand;
