import React, { useEffect, useRef } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import useResponsive from '@/hooks/useResponsive';
import gsap from 'gsap';

// Define interface for HeadlineProps
interface HeadlineProps extends TypographyProps {
  text: string | (string | React.ReactElement)[];
}

// Common styles for Typography elements
const commonStyles = {
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
};

// Headline component with responsive font sizing and animation
const Headline: React.FC<HeadlineProps> = ({ text, sx, ...rest }) => {
  // Responsive hook for screen size
  const isUnder400px = useResponsive('down', 400);
  // Ref for headline element to apply animation
  const headlineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headlineRef.current) {
      gsap.fromTo(headlineRef.current, { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1.5, ease: 'power3.out' });
    }
  }, []);

  return (
    <Typography
      ref={headlineRef}
      sx={{
        ...commonStyles,
        letterSpacing: -0.5,
        fontSize: `min(3rem, ${isUnder400px ? '1.5rem' : 'clamp(1.5rem, 3vw, 2.5rem)'})`,
        ...sx,
      }}
      {...rest}
    >
      {text}
    </Typography>
  );
};

// SubHeadline component with consistent styles and animation
export const SubHeadline: React.FC<HeadlineProps> = ({ text, sx, ...rest }) => {
  // Ref for subheadline element to apply animation
  const subHeadlineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (subHeadlineRef.current) {
      gsap.fromTo(subHeadlineRef.current, { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1.5, ease: 'power3.out' });
    }
  }, []);

  return (
    <Typography
      ref={subHeadlineRef}
      // variant={isMobile ? 'h5' : 'h4'}
      sx={{
        ...commonStyles,
        textAlign: 'center',
        letterSpacing: 0.5,
        fontSize: 'clamp(1.0rem, 2vw, 1.5rem)',
        fontWeight: 200,
        ...sx,
      }}
      {...rest}
    >
      {text}
    </Typography>
  );
};

export default Headline;
